import appStore from './app-store-button.png';
import chip from './chip.svg';
import b from './bpChip.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="parent">
          <img src={b} className="image1" alt="logo" />
          <img src={chip} className="App-logo" alt="logo" />
        </div>
        <h1 class="cubanoText">BetParty</h1>
        <div class="imgbox">
          <a
            className="App-link"
            href="https://apps.apple.com/us/app/betparty/id1438489465"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img class="center-fit" src={appStore} alt="Apple App Store Link"/>
          </a>
        </div>
      </header>
    </div>
  );
}

export default App;
